const maintenanceRouteName = 'maintenance'

export default defineNuxtRouteMiddleware((to) => {
  const maintenanceEnabled = process.env.MAINTENANCE_ENABLED === 'true'

  if (to.name !== maintenanceRouteName && maintenanceEnabled) {
    return navigateTo('/maintenance')
  }

  if (to.name === maintenanceRouteName && !maintenanceEnabled) {
    return navigateTo('/')
  }
})
